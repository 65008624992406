<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="closeFunc()">
          <ion-icon :icon="close"> </ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>Komentáře</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list v-if="comments.length > 0">
      <ion-item v-for="item in comments" lines="none" :key="item.id">
        <Comment
          :id="item.id"
          :isLiked="item.is_liked"
          :name="item.name"
          :avatar="item.avatar_url"
          :text="item.text"
          :likes="item.likes"
        />
      </ion-item>
    </ion-list>
    <ion-list v-else>
      <ion-item lines="none">
        <ion-label>
          <h2>Žadný komentář tu není</h2>
          <p>Buď první kdo tento příspěvek okomentuje...</p>
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
  <ion-footer>
    <ion-toolbar>
      <ion-input
        v-model="commentText"
        placeholder="Napište komentář..."
      ></ion-input>
      <ion-buttons slot="end">
        <ion-button :disabled="!canBePosted" @click="sendComment()">
          <ion-icon :icon="sendOutline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-footer>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  modalController,
  IonIcon,
  IonButton,
  IonButtons,
  IonList,
  IonInput,
  IonFooter,
  IonItem,
  IonLabel,
} from "@ionic/vue";
import { close, sendOutline } from "ionicons/icons";
import { mapGetters, mapActions } from "vuex";
import Comment from "./Comment.vue";

export default {
  name: "CommentModal",
  props: {
    postId: Number,
    comments: Array,
    groupId: Number,
    eventId: Number,
  },
  setup() {
    return { close, sendOutline };
  },
  watch: {
    commentText(value) {
      this.commentText = value;
      if (this.commentText.length > 0) {
        this.canBePosted = true;
      } else {
        this.canBePosted = false;
      }
    },
  },
  data() {
    return {
      commentText: "",
      canBePosted: false,
      // commentsArr: [],
    };
  },
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonButton,
    IonButtons,
    Comment,
    IonList,
    IonInput,
    IonFooter,
    IonItem,
    IonLabel,
  },
  beforeMount() {
    // this.commentsArr = this.comments;
  },
  methods: {
    ...mapActions({
      createNewPost: "social/createNewPost",
      commentPost: "social/commentPost",
    }),
    sendComment() {
      this.commentPost({
        networkId: this.getActiveId,
        postId: this.postId,
        text: this.commentText,
        groupId: this.groupId,
        eventId: this.eventId,
      }).then((res) => {
        this.commentText = "";
        // res["avatar_url"] = this.getUserAvatarUrl;
        // res["name"] = this.getUserName;
        // res["likes"] = 0;
        // res["is_liked"] = false;
        // this.commentsArr.unshift(res);
      });
    },
    closeFunc() {
      modalController.dismiss({
        dismissed: true,
        created: true,
      });
    },
  },
  computed: {
    ...mapGetters({
      getUserAvatarUrl: "auth/getUserAvatarUrl",
      getUserName: "auth/getUserName",
      getActiveId: "social/getActiveId",
    }),
  },
};
</script>

<style scoped>
ion-content,
ion-footer {
  --ion-background-color: var(--ion-card-background);
}
ion-avatar {
  width: 75px;
  height: 75px;
}
ion-chip ion-label {
  margin-left: 2px;
}
ion-label {
  margin-left: 12px;
}
ion-input {
  width: 95%;
  display: inline-block;
  background: var(--ion-color-step-150);
  border-radius: 50px;
  margin: 0 5px;
}
.sendBtn {
  position: relative;
  top: 6px;
  font-size: 25px;
  text-align: center;
  margin-left: 10px;
}

.ios ion-input {
  --padding-start: 10px !important;
}
</style>
