<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="closeFunc()">
          <ion-icon :icon="close"> </ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button :disabled="!canBePosted" @click="newPost()">
          Přidat
        </ion-button>
      </ion-buttons>
      <ion-title>Přidat příspěvek</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-item lines="none" className="post-header ion-no-margin ion-no-padding">
      <ion-avatar slot="start" className="ion-no-padding ion-no-margin">
        <ion-img :src="`${avatar}`" />
      </ion-avatar>
      <ion-label>
        <h3 class="name">{{ name }}</h3>
        <p class="info">
          <ion-chip outline color="dark">
            <template v-if="eventId == 0 && groupId == 0">
              <ion-icon :icon="earth"></ion-icon>
              <ion-label>Veřejné</ion-label>
            </template>
            <template v-if="eventId != 0">
              <ion-icon :icon="calendarOutline"></ion-icon>
              <ion-label>Událost</ion-label>
            </template>
            <template v-if="groupId != 0">
              <ion-icon :icon="peopleCircleOutline"></ion-icon>
              <ion-label>Skupina</ion-label>
            </template>
          </ion-chip>
        </p>
      </ion-label>
    </ion-item>
    <ion-item>
      <ion-textarea
        autoGrow
        autofocus
        v-model="postText"
        placeholder="Co se Vám honí hlavou?"
      ></ion-textarea>
    </ion-item>
    <ion-item-group>
      <ion-item-divider color="light">
        <ion-label>Obrázek</ion-label>
      </ion-item-divider>
      <template v-if="isWeb">
        <ion-item>
          <input
            type="file"
            id="image"
            name="image"
            accept="image/png, image/jpeg"
            ref="postImg"
            @change="previewFiles"
          />
          <ion-icon
            v-if="postImage != ''"
            color="primary"
            slot="end"
            @click="removeImage()"
            :icon="trash"
          ></ion-icon>
        </ion-item>
      </template>
      <template v-else>
        <ion-item>
          <ion-icon
            color="primary"
            slot="start"
            @click="loadImage()"
            :icon="addCircle"
          ></ion-icon>
          <ion-label> {{ imageName }} </ion-label>
          <ion-icon
            v-if="postImage != ''"
            color="primary"
            slot="end"
            @click="removeImage()"
            :icon="trash"
          ></ion-icon>
        </ion-item>
      </template>
    </ion-item-group>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  modalController,
  IonIcon,
  IonButton,
  IonButtons,
  IonItem,
  IonTextarea,
  IonImg,
  IonAvatar,
  IonLabel,
  IonChip,
  isPlatform,
  IonItemDivider,
  IonNote,
  IonItemGroup,
} from "@ionic/vue";
import {
  close,
  earth,
  peopleCircleOutline,
  calendarOutline,
  addCircle,
  trash,
} from "ionicons/icons";
import { mapGetters, mapActions } from "vuex";

import { getPicture } from "../composable/photoPicker";

export default {
  name: "NewPostModal",
  setup() {
    return {
      close,
      earth,
      peopleCircleOutline,
      calendarOutline,
      addCircle,
      trash,
    };
  },
  watch: {
    postText(value) {
      this.postText = value;
      if (this.postText.length > 0) {
        this.canBePosted = true;
      } else {
        this.canBePosted = false;
      }
    },
  },
  data() {
    return {
      postText: "",
      canBePosted: false,
      avatar: "",
      name: "",
      postImage: "",
      imageName: "",
    };
  },
  props: {
    eventId: Number,
    groupId: Number,
  },
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonButton,
    IonButtons,
    IonItem,
    IonTextarea,
    IonImg,
    IonAvatar,
    IonLabel,
    IonChip,
    IonItemDivider,
    IonItemGroup,
  },
  beforeMount() {
    this.avatar = this.getUserAvatarUrl;
    this.name = this.getUserName;
  },
  methods: {
    ...mapActions({
      createNewPost: "social/createNewPost",
    }),
    closeFunc() {
      modalController.dismiss({
        dismissed: true,
        created: false,
      });
    },
    newPost() {
      let self = this;
      let event = 0;
      let group = 0;

      if (this.eventId > 0) {
        event = this.eventId;
      }
      if (this.groupId > 0) {
        group = this.groupId;
      }

      this.createNewPost({
        network: self.getActiveId,
        text: self.postText,
        eventId: event,
        groupId: group,
        postImg: self.postImage,
      }).then((res) => {
        console.log("res");
        modalController.dismiss({
          dismissed: true,
          created: true,
        });
      });
    },
    loadImage() {
      getPicture().then((res) => {
        console.log("GET PICTURE RESULT", res);
        this.imageName = res.name;
        this.postImage = res.data;
      });

      // Chooser.getFile("image/jpeg,image/png")
      //   .then((res) => {
      //     console.log(res);
      //     this.imageName = res.name;
      //     this.postImage = new Blob([res.data], {
      //       type: res.mediaType,
      //     });
      //   })
      //   .catch((err) => {
      //     alert("Obrázek se nepodařilo nahrát");
      //   });
    },
    removeImage() {
      this.postImage = "";
      this.imageName = "";
      if (this.isWeb) this.$refs.postImg.value = "";
      // console.log(this.postImage, this.imageName);
    },
    previewFiles(event) {
      this.postImage = event.target.files[0];
      // this.loadImage();
    },
  },
  computed: {
    ...mapGetters({
      getUserAvatarUrl: "auth/getUserAvatarUrl",
      getUserName: "auth/getUserName",
      getActiveId: "social/getActiveId",
    }),
    isWeb() {
      console.log("IsWEB", isPlatform("mobileweb"), isPlatform("desktop"));
      return isPlatform("mobileweb") || isPlatform("desktop");
    },
  },
};
</script>

<style scoped>
ion-avatar {
  width: 75px;
  height: 75px;
}
ion-chip ion-label {
  margin-left: 2px;
}
ion-label {
  margin-left: 12px;
}
ion-item-group {
  border: 1px solid var(--ion-color-medium);
  margin-top: 16px;
}
.name {
  font-weight: bold;
  font-size: 16px;
  margin-left: 4px;
}
.info {
  color: #636366;
  font-size: 14px;
}
.post-header {
  margin-bottom: 35px;
}
ion-content {
  --ion-background-color: var(--ion-card-background);
  --background: var(--ion-card-background);
  background: var(--background);
}
</style>
