<template class="comment">
  <ion-avatar slot="start" class="ios hydrated">
    <ion-img :src="`${avatar}`" />
  </ion-avatar>
  <ion-label>
    <div class="gray-label">
      <h2>{{ name }}</h2>
      <p>{{ text }}</p>
    </div>
    <div>
      <span class="footer"
        ><span @click="likeThisComment()">
          <ion-icon v-if="isLikedByUser" class="liked" :icon="heart"></ion-icon>
          <ion-icon v-else :icon="heartOutline"></ion-icon> </span
        ><span class="num">{{ likes }}</span></span
      >
    </div>
  </ion-label>
</template>

<script>
import { IonImg, IonLabel, IonAvatar, IonIcon } from "@ionic/vue";
import { heartOutline, heart } from "ionicons/icons";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Comment",
  props: {
    id: Number,
    isLiked: Boolean,
    name: String,
    avatar: String,
    text: String,
    likes: Number,
  },
  data() {
    return {
      numberOfLikes: 0,
      isLikedByUser: false,
    };
  },
  components: {
    IonLabel,
    IonAvatar,
    IonImg,
    IonIcon,
  },
  setup() {
    return {
      heartOutline,
      heart,
    };
  },
  beforeMount() {
    // this.numberOfLikes = this.likes;
    this.isLikedByUser = this.isLiked;
  },
  computed: {
    ...mapGetters({ getActiveId: "social/getActiveId" }),
  },
  methods: {
    ...mapActions({
      likeComment: "social/likeComment",
    }),
    likeThisComment() {
      let self = this;
      this.likeComment({
        networkId: this.getActiveId,
        commentId: this.id,
      }).then((res) => {
        // self.numberOfLikes = res;
        self.isLikedByUser = !self.isLikedByUser;
      });
    },
  },
};
</script>

<style scoped>
.comment {
  margin-bottom: 25px;
}
.gray-label {
  background: var(--ion-color-step-150);
  border-radius: 10px;
  padding: 10px;
}
ion-label p {
  /* color: var(--ion-color-step-300); */
}
ion-label h2 {
  font-weight: bold;
}
.footer .liked {
  color: #e51934;
}

.footer ion-icon {
  position: relative;
  top: 4px;
  font-size: 18px;
}
.footer .num {
  margin-left: 5px;
}
</style>
