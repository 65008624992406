/* Platfrom specific: Musí se přidat opravnění v nastavení platformy přímo v projektu AndroidStudio/XCode
 *
 * --- ANDROID (AndroidManifest.xml) ---
 *
 * android.permission.WRITE_EXTERNAL_STORAGE
 * android.permission.READ_EXTERNAL_STORAGE
 *
 * --- IOS (Info.plist) --- https://capacitorjs.com/docs/ios/configuration#configuring-infoplist
 *
 * NSCameraUsageDescription (Privacy - Camera Usage Description)
 * NSPhotoLibraryAddUsageDescription (Privacy - Photo Library Additions Usage Description)
 * NSPhotoLibraryUsageDescription (Privacy - Photo Library Usage Description)
 *
 */

import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";

const getPicture = async () => {
  //   console.log("PREMISSIONS", Camera.checkPermissions());

  //získání aktualních opravnění
  let perm = Camera.checkPermissions();

  //Zkouška + vyžádání opravnění
  if (perm.camera != "granted" && perm.photos != "granted") {
    Camera.requestPermissions(["camera", "photos"]);
  } else if (perm.camera != "granted") {
    Camera.requestPermissions(["camera"]);
  } else if (perm.photos != "granted") {
    Camera.requestPermissions(["photos"]);
  }

  const image = await Camera.getPhoto({
    quality: 90,
    allowEditing: false,
    resultType: CameraResultType.DataUrl,
    source: CameraSource.Prompt,
    promptLabelHeader: "Vybrat obrázek",
    promptLabelCancel: "Zrušit",
    promptLabelPhoto: "Ze zařízení",
    promptLabelPicture: "Vyfotit",
  });

  let retObject = {};

  //Vytvoří z DataURL -> Image File (Blob)
  const blob = await (await fetch(image.dataUrl)).blob();
  //   debugger;
  retObject["data"] = blob;
  retObject["memtype"] = "image/" + image.format;
  retObject["name"] = "Obrázek přidán";
  return retObject;
};

export { getPicture };
