<template>
  <div class="post-container">
    <ion-item lines="none" className="post-header ion-no-margin ion-no-padding">
      <ion-avatar slot="start" className="ion-no-padding ion-no-margin">
        <ion-img :src="`${avatar}`" />
      </ion-avatar>
      <ion-label>
        <h3 class="name">{{ name }}</h3>
        <p class="info">
          {{ formattedDate }} &middot;
          <ion-icon :icon="globeOutline"></ion-icon>
        </p>
      </ion-label>
    </ion-item>
    <div className="post-content">
      <template v-if="postImg != undefined && postImg != null && postImg != {}">
        <ion-img class="post-image" :src="postImg.url"> </ion-img>
      </template>
      <p>{{ text }}</p>
    </div>
    <div className="post-footer">
      <span class="left"
        ><span @click="likeThis()">
          <ion-icon v-if="isLikedByUser" class="liked" :icon="heart"></ion-icon>
          <ion-icon v-else :icon="heartOutline"></ion-icon> </span
        ><span>{{ likes }}</span></span
      >
      <span class="right" @click="openCommentsModal()">
        Komentáře ({{ numOfComments }})
      </span>
    </div>
  </div>
</template>

<script>
import { globeOutline, heartOutline, heart } from "ionicons/icons";
import {
  IonItem,
  IonAvatar,
  IonImg,
  IonLabel,
  IonIcon,
  modalController,
} from "@ionic/vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import CommentsModal from "./CommentsModal.vue";

export default {
  props: {
    avatar: String,
    id: Number,
    name: String,
    sponsored: Boolean,
    time: Number,
    text: String,
    likes: Number,
    isLiked: Boolean,
    numOfComments: Number,
    comments: Array,
    postImg: Object,
    groupId: Number,
    eventId: Number,
  },
  data() {
    return {
      isLikedByUser: false,
    };
  },
  components: {
    IonItem,
    IonAvatar,
    IonImg,
    IonLabel,
    IonIcon,
  },
  setup() {
    return {
      globeOutline,
      heartOutline,
      heart,
    };
  },
  computed: {
    formattedDate() {
      // console.log("moment", this.time);
      // moment().locale("cs");
      return moment.unix(this.time).locale("cs").fromNow();
    },
    ...mapGetters({
      getActiveId: "social/getActiveId",
    }),
  },
  beforeMount() {
    this.isLikedByUser = this.isLiked;
    // this.numberOfLikes = this.likes;
  },
  methods: {
    ...mapActions({
      likePost: "social/likePost",
    }),
    likeThis() {
      let self = this;
      this.likePost({ network: this.getActiveId, post: this.id }).then(
        (res) => {
          self.numberOfLikes = res;
          self.isLikedByUser = !self.isLikedByUser;
        }
      );
    },

    async openCommentsModal() {
      let self = this;
      const modal = await modalController.create({
        component: CommentsModal,
        cssClass: "comments-modal",
        componentProps: {
          postId: self.id,
          comments: self.comments,
          groupId: self.groupId,
          eventId: self.eventId,
        },
      });

      modal.present();

      modal.onDidDismiss().then((data) => {
        // self.loadPosts();
      });
    },
  },
};
</script>

<style scoped>
.post-container {
  box-shadow: 0px -0.5px 0px rgba(0, 0, 0, 0.05),
    0px 0.5px 0px rgba(0, 0, 0, 0.05);
  padding: 8px 8px;
  background: var(--ion-card-background) !important;
}

ion-avatar {
  width: 48px;
  height: 48px;
}
.name {
  font-weight: bold;
  font-size: 16px;
}
.info {
  color: #636366;
  font-size: 14px;
}
.info ion-icon {
  position: relative;
  top: 2px;
  font-size: 14px;
}
ion-label {
  margin-left: 12px;
}
.post-footer {
  color: #636366;
}
.post-footer ion-icon {
  position: relative;
  top: 4px;
  font-size: 20px;
  margin-right: 8px;
}
.post-footer .liked {
  color: #e51934;
}
.post-footer .right {
  float: right;
}
.post-image::part(image) {
  max-height: 25vh;
}
</style>
